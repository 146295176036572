.root {
    background-color: lightgoldenrodyellow;

    box-shadow: 3px 4px 10px 2px rgba(0, 0, 0, 0.5);
}

.grid {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-template-rows: repeat(auto-fill, auto);
}

.grid>* {
    padding: 2vmin 3vmin;
}

.title {
    background-color: brown;
    color: white;
    font-weight: bold;
}

.pointTotal {
    grid-column: 5 / span 2;
    background-color: brown;
    color: white;
    text-align: right;
}

.ruler {
    margin: 0;
    border-color: brown;
}

.buttonContainer {
    padding: 2vmin 3vmin;
}

.button {
    background-color: brown;
    border: 1px solid brown;
    color: white;
    border-radius: 5px;
    padding: 1vmin;
    cursor: pointer;
}