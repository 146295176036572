@import 'normalize.css';
@import url('https://fonts.googleapis.com/css?family=Anton&display=swap');

html {
    box-sizing: border-box;

    font-family: 'Anton', sans-serif;
    font-size: calc(1vw + 1vh + .5vmin);
}

*,
*:before,
*:after {
    box-sizing: inherit;
    user-select: none;
}

html,
body {
    height: 100vh;
    width: 100vw;
}