.input {
    border: none;
    background-color: inherit;
    outline: none;
    width: 10vw;
}

.button {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.center {
    text-align: center;
}